<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="2">
        <v-card>
          <v-card-title>
            Очистка локального хранилища
          </v-card-title>
          <v-card-subtitle>
            Очищает данные сохраненые в LocalStorage для данного сайта: справочники, данные сессии.<br>
            После завершения будет выполнен выход.
          </v-card-subtitle>
          <v-card-actions class="mt-10">
            <v-btn
              color="red"
              @click="clearLocalStorage"
            >
              <v-icon>mdi-delete</v-icon>    Стереть данные
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>

export default {
  name:'SettingsMain',
  methods:{
    clearLocalStorage(){
      try{
        this.$store.commit('catalogs/RESET_STATE')
        this.$store.commit('auth/RESET_STATE')
        this.$router.push({path:'/login'})
        this.$snackbar({text:'Хранилище очищено', top:false, right: false})
      }catch(e){
        this.$snackbar({text:'Ошибка', color:'red', top:false, right: false})
      }
    }
  }
}
</script>